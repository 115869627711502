@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Pacifico&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #526D82;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9;
}

[data-testid="modal-surface"] {
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: none!important;
}

[style*="justify-content: flex-start; flex-direction: column-reverse;"] {
    width: auto!important; 
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}